import React from 'react';
import './css/about.css';
import certificate1 from './assets/certificate1.png';
import certificate2 from './assets/certificate2.png';
import certificate3 from './assets/certificate3.png';
import certificate4 from './assets/certificate4.png';
import certificate5 from './assets/certificate5.png';
import certificate6 from './assets/certificate6.png';
import certificate7 from './assets/certificate7.png';
import certificate8 from './assets/certificate8.png';
import certificate9 from './assets/certificate9.png';
import certificate10 from './assets/certificate10.png';

const About = () => {
  const certificates = [
    { src: certificate1, title: 'CompTIA Security+ ce Certification', skills: 'Risk Management, Threats, Identity & Access Management' },
  { src: certificate2, title: 'CompTIA Network+ ce Certification', skills: 'Network Troubleshooting, Network Management, Network Protocols' },
  { src: certificate3, title: 'CompTIA A+ ce Certification', skills: 'Hardware and Software Troubleshooting, Networking, Operating Systems' },
  { src: certificate4, title: 'AWS Certified DevOps Engineer � Professional', skills: 'Continuous Integration, Continuous Deployment, Automation, Infrastructure as Code' },
  { src: certificate5, title: 'AWS Certified Developer � Associate', skills: 'AWS Services, Application Development, Cloud Architecture' },
  { src: certificate6, title: 'AWS Cloud Practitioner', skills: 'Cloud Computing, AWS Services, Cloud Economics' },
  { src: certificate7, title: 'CompTIA Cloud+ ce Certification', skills: 'Cloud Architecture, Virtualization, Cloud Security' },
  { src: certificate8, title: 'CompTIA CySA+ ce Certification', skills: 'Threat Detection, Incident Response, Security Monitoring' },
  { src: certificate9, title: 'CompTIA PenTest+ ce Certification', skills: 'Penetration Testing, Vulnerability Analysis, Risk Management' },
  { src: certificate10, title: 'ISC2 Certified in Cybersecurity', skills: 'Cybersecurity Fundamentals, Security Operations' },
  ];

  return (
    <section id="about" className="about-section">
      <div className="container">
        <h2>About Me</h2>
        <p>
          Motivated and technically proficient IT professional with hands-on experience in virtualization, network security, server administration, and systems monitoring. Skilled in deploying and managing Proxmox clusters, pfSense firewalls, SIEM solutions, and Windows Server environments.
        </p>
        <p>
          Adept at implementing high availability, configuring complex network infrastructures, and securing systems through advanced firewall and IPS configurations. Seeking an opportunity to grow and develop within a forward-thinking organization committed to innovation and excellence in technology.
        </p>
        <h3>Certificates</h3>
        <div className="hexagon-grid">
          {certificates.map((cert, index) => (
            <div key={index} className={`hexagon-container hexagon-${index + 1}`}>
              <div className="hexagon">
                <div className="hexagon-in1">
                  <div className="hexagon-in2">
                    <img src={cert.src} alt={cert.title} className="hexagon-img" />
                  </div>
                </div>
              </div>
              <div className="hexagon-hover">
                <h4>{cert.title}</h4>
                <p>{cert.skills}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default About;
