import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './css/style.css'
import { FaGithub, FaLinkedinIn } from "react-icons/fa";
import { SiCredly, SiGmail } from "react-icons/si";
import codingImage from './assets/coding.png';
import dogImage from './assets/dog.gif';
import resume from './assets/CarlRedactedResume.pdf';
const codingPng = <img src={codingImage} alt='coding icon' className='codingIcon' />;
const dogGif = <img src={dogImage} alt='dog gif' className='dogGif' />;
const Home = () => {

  return (
    <Container fluid >
      <Row className='rowBody fluid mx-auto mw-80'>
        <Col xs={12} md={12} lg={6} className="justify-content-center">
          <h1 className='greeting-text '>Hi all, I'm Carl <span className='wave-emoji'><img src='https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f44b.png' alt='👋' className='hands tilt-animation'></img></span> </h1>
          <p className='greeting-parag float-left text-wrap text-break '> I'm an IT Professional {codingPng} specializing in Cloud, Security, and Automation, with experience in managing enterprise networks, configuring firewalls,
          and automating workflows. I’ve worked on projects involving AWS CodePipeline, serverless web applications, Kubernetes, and scripting for system automation. Passionate about Cybersecurity and Cloud Engineering, 
          I enjoy solving complex infrastructure challenges and improving security in cloud and on-prem environments.</p>
          <div className='svgIcons'>
            <a href='https://github.com/carlfombuena' className=' fs-1 text-white rounded-circle bg-dark ' target="_blank" rel="noreferrer"> <FaGithub /></a>
            <a href='https://www.linkedin.com/in/carl-fombuena-9730aa184' className=' fs-1 text-white rounded-circle bg-primary  ms-3' target="_blank" rel="noreferrer"> < FaLinkedinIn /></a>
            <a href='mailto:carlfombuena@gmail.com' className=' fs-1 text-white rounded-circle bg-danger ms-3' target="_blank" rel="noreferrer"> < SiGmail /></a>
            <a href='https://www.credly.com/users/carl-fombuena' className=' fs-1 text-warning rounded-circle bg-dark ms-3' target="_blank" rel="noreferrer"> < SiCredly /></a>
          </div>

          <div className='contactmeButton'>
            <a href='/contact'> <button type="button" className='contactBtn btn btn-secondary'>CONTACT ME</button> </a>
            <a href={resume} download target='_blank' rel="noreferrer"><button type="button" className='resumeBtn btn btn-secondary '>SEE MY RESUME</button></a>
          </div>

        </Col>

        <Col xs={12} md={12} lg={6} className="row g-0">
          {dogGif}
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
